export enum ReducerActionType {
  SET_AGE_GROUP = 'SET_AGE_GROUP',
  SET_UNIT_SYSTEM = 'SET_UNIT_SYSTEM',
  SET_GENDER = 'SET_GENDER',
  /* PLOP_REDUCER_ACTION_ENUM */
  SET_SEE3 = 'SET_SEE3',
  SET_SEE2 = 'SET_SEE2',
  SET_SEE1 = 'SET_SEE1',
  SET_MOTIVATION = 'SET_MOTIVATION',
  SET_LIFE_EVENTS = 'SET_LIFE_EVENTS',
  SET_BAD_HABITS = 'SET_BAD_HABITS',
  SET_CHILDHOOD_TRAUMA = 'SET_CHILDHOOD_TRAUMA',
  SET_SLEEP = 'SET_SLEEP',
  SET_HEALTH_CONDITIONS = 'SET_HEALTH_CONDITIONS',
  SET_MEDICATIONS = 'SET_MEDICATIONS',
  SET_DESCRIBES_BEST = 'SET_DESCRIBES_BEST',
  SET_WATER = 'SET_WATER',
  SET_WALKING = 'SET_WALKING',
  SET_STAIRS = 'SET_STAIRS',
  SET_JOB_ACTIVE = 'SET_JOB_ACTIVE',
  SET_JOB_LOAD = 'SET_JOB_LOAD',
  SET_MEALS_FEEL = 'SET_MEALS_FEEL',
  SET_TIME_DINNER = 'SET_TIME_DINNER',
  SET_TIME_LUNCH = 'SET_TIME_LUNCH',
  SET_TIME_BREAKFAST = 'SET_TIME_BREAKFAST',
  SET_LAST_HAPPY_WEIGHT = 'SET_LAST_HAPPY_WEIGHT',
  SET_TARGET_BODY_TYPE = 'SET_TARGET_BODY_TYPE',
  SET_HEALTH_GOALS = 'SET_HEALTH_GOALS',
  SET_CURRENT_BODY_TYPE = 'SET_CURRENT_BODY_TYPE',
  SET_FOCUS = 'SET_FOCUS',
  SET_TARGET_ZONES = 'SET_TARGET_ZONES',
  SET_FITNESS_LEVEL = 'SET_FITNESS_LEVEL',
  SET_LIFE_BUSYNESS = 'SET_LIFE_BUSYNESS',
  SET_WEIGHT_GAIN_REASONS = 'SET_WEIGHT_GAIN_REASONS',
  SET_HEALTH_ISSUES = 'SET_HEALTH_ISSUES',
  SET_TRIED_DIETS = 'SET_TRIED_DIETS',
  SET_EMAIL_CONSENT = 'SET_EMAIL_CONSENT',
  SET_NUTRITION_FOOD_CRAVINGS = 'SET_NUTRITION_FOOD_CRAVINGS',
  SET_NUTRITION_HUNGRY_TIME_OF_DAY = 'SET_NUTRITION_HUNGRY_TIME_OF_DAY',
  SET_NUTRITION_STRESS_EATING = 'SET_NUTRITION_STRESS_EATING',
  SET_NUTRITION_PREFERRED_MEAL = 'SET_NUTRITION_PREFERRED_MEAL',
  SET_NUTRITION_EATING_HABITS = 'SET_NUTRITION_EATING_HABITS',
  SET_NUTRITION_HOW_TO_FIT_IN_MY_LIFE = 'SET_NUTRITION_HOW_TO_FIT_IN_MY_LIFE',
  SET_BEHAVIOR_PUSH = 'SET_BEHAVIOR_PUSH',
  SET_BEHAVIOR_GUILT = 'SET_BEHAVIOR_GUILT',
  SET_BEHAVIOR_SOCIAL = 'SET_BEHAVIOR_SOCIAL',
  SET_BEHAVIOR_TRIGGER = 'SET_BEHAVIOR_TRIGGER',
  SET_BEHAVIOR_ST_PLATE = 'SET_BEHAVIOR_ST_PLATE',
  SET_BEHAVIOR_ST_SOCIALIZE = 'SET_BEHAVIOR_ST_SOCIALIZE',
  SET_WEIGHT_LOSS_PACE = 'SET_WEIGHT_LOSS_PACE',
  SET_WEIGHT_LOSS_FOCUS = 'SET_WEIGHT_LOSS_FOCUS',
  SET_DEBQ_EXTERNAL_SNACKING_WHILE_COOKING = 'SET_DEBQ_EXTERNAL_SNACKING_WHILE_COOKING',
  SET_DEBQ_EXTERNAL_EAT_MORE_WHEN_OTHERS_EATING = 'SET_DEBQ_EXTERNAL_EAT_MORE_WHEN_OTHERS_EATING',
  SET_DEBQ_EXTERNAL_HARD_TO_RESIST_TASTY_FOOD = 'SET_DEBQ_EXTERNAL_HARD_TO_RESIST_TASTY_FOOD',
  SET_DEBQ_EXTERNAL_EAT_WHEN_SEE_EATING_PEOPLE = 'SET_DEBQ_EXTERNAL_EAT_WHEN_SEE_EATING_PEOPLE',
  SET_DEBQ_EXTERNAL_BUY_FOOD_WHEN_PASSING_CAFE = 'SET_DEBQ_EXTERNAL_BUY_FOOD_WHEN_PASSING_CAFE',
  SET_DEBQ_EXTERNAL_BUY_FOOD_WHEN_PASSING_BAKERY = 'SET_DEBQ_EXTERNAL_BUY_FOOD_WHEN_PASSING_BAKERY',
  SET_DEBQ_EXTERNAL_EAT_DELICIOUS_FOOD_INSTANTLY = 'SET_DEBQ_EXTERNAL_EAT_DELICIOUS_FOOD_INSTANTLY',
  SET_DEBQ_EXTERNAL_EAT_IF_GOOD_LOOKING_FOOD = 'SET_DEBQ_EXTERNAL_EAT_IF_GOOD_LOOKING_FOOD',
  SET_DEBQ_EXTERNAL_EAT_MORE_WHEN_GOOD_LOOKING_FOOD = 'SET_DEBQ_EXTERNAL_EAT_MORE_WHEN_GOOD_LOOKING_FOOD',
  SET_DEBQ_EXTERNAL_EAT_TASTY_FOOD = 'SET_DEBQ_EXTERNAL_EAT_TASTY_FOOD',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_BORED = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_BORED',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_EMOTIONAL_UPSET = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_EMOTIONAL_UPSET',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_DISAPPOINTED = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_DISAPPOINTED',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_SCARED = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_SCARED',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_FAILED = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_FAILED',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_WORRIED = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_WORRIED',
  SET_DEBQ_EMOTIONAL_EAT_DURING_UNPLEASANT_ANTICIPATION = 'SET_DEBQ_EMOTIONAL_EAT_DURING_UNPLEASANT_ANTICIPATION',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_UPSET = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_UPSET',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_LET_DOWN = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_LET_DOWN',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_LONELY = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_LONELY',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_DEPRESSED = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_DEPRESSED',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_CHILLING = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_CHILLING',
  SET_DEBQ_EMOTIONAL_EAT_WHEN_IRRITATED = 'SET_DEBQ_EMOTIONAL_EAT_WHEN_IRRITATED',
  SET_DEBQ_RESTRAINED_REFUSE_FOOD = 'SET_DEBQ_RESTRAINED_REFUSE_FOOD',
  SET_DEBQ_RESTRAINED_CONSIDER_WEIGHT_WHEN_CHOOSE_FOOD = 'SET_DEBQ_RESTRAINED_CONSIDER_WEIGHT_WHEN_CHOOSE_FOOD',
  SET_DEBQ_RESTRAINED_NO_EVENING_EATING = 'SET_DEBQ_RESTRAINED_NO_EVENING_EATING',
  SET_DEBQ_RESTRAINED_NO_EAT_BETWEEN_MEALS = 'SET_DEBQ_RESTRAINED_NO_EAT_BETWEEN_MEALS',
  SET_DEBQ_RESTRAINED_EAT_LESS = 'SET_DEBQ_RESTRAINED_EAT_LESS',
  SET_DEBQ_RESTRAINED_EAT_LESS_AFTER_OVEREATING = 'SET_DEBQ_RESTRAINED_EAT_LESS_AFTER_OVEREATING',
  SET_DEBQ_RESTRAINED_FOOD_FOR_WL = 'SET_DEBQ_RESTRAINED_FOOD_FOR_WL',
  SET_DEBQ_RESTRAINED_EATING_MONITORING = 'SET_DEBQ_RESTRAINED_EATING_MONITORING',
  SET_DEBQ_RESTRAINED_EAT_LESS_DURING_MEALS = 'SET_DEBQ_RESTRAINED_EAT_LESS_DURING_MEALS',
  SET_DEBQ_RESTRAINED_EAT_LESS_WHEN_GAIN_WEIGHT = 'SET_DEBQ_RESTRAINED_EAT_LESS_WHEN_GAIN_WEIGHT',
  SET_HEIGHT = 'SET_HEIGHT',
  SET_AGE = 'SET_AGE',
  SET_CURRENT_WEIGHT = 'SET_CURRENT_WEIGHT',
  SET_TARGET_WEIGHT = 'SET_TARGET_WEIGHT',
  SET_OCCASION = 'SET_OCCASION',
  SET_OCCASION_DATE = 'SET_OCCASION_DATE',
  SET_WEIGHT_LOSS_MOTIVATIONS = 'SET_WEIGHT_LOSS_MOTIVATIONS',
  SET_HEALTH_GOAL_REASON = 'SET_HEALTH_GOAL_REASON',
  SET_COUNTRY = 'SET_COUNTRY',
}

export enum AgeGroup {
  GROUP_A = '18-25',
  GROUP_B = '26-35',
  GROUP_C = '36-45',
  GROUP_D = '46+',
}

export enum UnitsSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export enum WeightUnit {
  LB = 'lbs',
  KG = 'kg',
}

export enum HeightUnit {
  IN = 'in',
  CM = 'cm',
}

export type EmailConsent = boolean

/* PLOP_STATE_VALUE_ENUM */
export enum See3 {
  ALGAE = 'algae',
  WOMAN = 'woman',
  MONSTER = 'monster',
  MERMAID = 'mermaid',
}

export enum See2 {
  BUTTERFLY = 'butterfly',
  BAT = 'bat',
  KNIFE = 'knife',
  FACE = 'face',
}

export enum See1 {
  HEART = 'heart',
  TREE = 'tree',
  LUNGS = 'lungs',
  ROAD = 'road',
}

export enum Motivation {
  SEE_WHAT_THE_BUZZ_IS = 'see_what_the_buzz_is',
  TRY_TO_LOSE_WEIGHT = 'try_to_lose_weight',
  SERIOUS_ABOUT_LOSING_WEIGHT = 'serious_about_losing_weight',
}

export enum LifeEvents {
  MARRIAGE_OR_RELATIONSHIP = 'marriage_or_relationship',
  PREGNANCY = 'pregnancy',
  BUSY_WORK_OR_FAMILY_LIFE = 'busy_work_or_family_life',
  STRESS_OR_MENTAL_HEALTH = 'stress_or_mental_health',
  MENOPAUSE = 'menopause',
  NONE = 'none',
}

export enum BadHabits {
  SWEET_TOOTH = 'sweet_tooth',
  TOO_MUCH_SODA = 'too_much_soda',
  MIDNIGHT_SNACKS = 'midnight_snacks',
  DRINK_ALCOHOL = 'drink_alcohol',
  NOT_ENOUGH_SLEEP = 'not_enough_sleep',
  NONE = 'none',
}

export enum ChildhoodTrauma {
  NO_SUPPORT = 'no_support',
  FAMILY_POVERTY = 'family_poverty',
  OVERPROTECTION = 'overprotection',
  BULLYING_ISOLATION = 'bullying_isolation',
  EATING_PRESSURE = 'eating_pressure',
}

export enum HealthConditions {
  NONE = 'none',
  DIABETES = 'diabetes',
  HEART_DISEASE = 'heart_disease',
  HIGH_BLOOD_PRESSURE = 'high_blood_pressure',
  HIGH_CHOLESTEROL = 'high_cholesterol',
  MENTAL_HEALTH_DISORDERS = 'mental_health_disorders',
  CKD = 'ckd',
  CANCER = 'cancer',
  GASTRO = 'gastro',
  PHYS_DISABILITY = 'phys_disability',
  MENOPAUSE = 'menopause',
  OTHER = 'other',
}

export enum Medications {
  VITAMINS = 'vitamins',
  HORMONES = 'hormones',
  ANTIBIOTICS = 'antibiotics',
  WL_MEDICATION = 'wl_medication',
  NONE = 'none',
}

export enum DescribesBest {
  STRUGGLE_BUILD = 'struggle_build',
  GAIN_LOSE_WEIGHT_EASILY = 'gain_lose_weight_easily',
  GAIN_EASILY_LOSE_TOUGH = 'gain_easily_lose_tough',
  NOT_SURE = 'not_sure',
}

export enum Sleep {
  LESS_THAN_FIVE = 'less_than_five',
  FIVE_TO_SIX = 'five_to_six',
  SEVEN_TO_EIGHT = 'seven_to_eight',
  OVER_EIGHT = 'over_eight',
}

export enum Water {
  NONE = 'none_glasses',
  TWO_GLASSES = 'two_glasses',
  TWO_SIX_GLASSES = 'two_six_glasses',
  SIX_MORE_GLASSES = 'six_more_glasses',
}

export enum Walking {
  LESS_THAN_TWENTY_MINUTES = 'less_20_minutes',
  TWENTY_SIXTY_MINUTES = '20_60_minutes',
  MORE_SIXTY_MINUTES = '60_minutes',
}

export enum Stairs {
  CANNOT_TALK = 'cannot_talk',
  CAN_TALK = 'can_talk',
  OK_FEEL = 'ok_feel',
  EASY_FEEL = 'easy_feel',
}

export enum JobActive {
  FAIRLY_INACTIVE = 'fairly_inactive',
  PHYSICAL_LABOR = 'physical_labor',
  BIT_BOTH = 'bit_both',
}

export enum JobLoad {
  NINE_TO_FIVE = 'nine_to_five',
  NIGHT_SHIFTS = 'night_shifts',
  FAIRLY_FLEXIBLE = 'fairly_flexible',
  DONT_WORK = 'dont_work',
}

export enum MealsFeel {
  SLEEPY_WHEN_HUNGRY = 'sleepy_when_hungry',
  TIRED_AFTER_EAT = 'tired_after_eat',
  FEEL_GOOD = 'feel_good',
  FEEL_ENERGY = 'feel_energy',
}

export enum TimeDinner {
  ONE = 'before_4_pm',
  TWO = 'between_4_7_pm',
  THREE = 'after_7_pm',
  FOUR = 'skip_dinner',
}

export enum TimeLunch {
  ONE = 'before_1_pm',
  TWO = 'between_1_2_pm',
  THREE = 'between_2_4_pm',
  FOUR = 'skip_lunch',
}

export enum TimeBreakfast {
  ONE = 'before_7_am',
  TWO = 'after_7_am',
  THREE = 'between_9_11_am',
  FOUR = 'skip_breakfast',
}

export enum LastHappyWeight {
  LESS_THAN_YEAR_AGO = 'less_than_year_ago',
  ONE_TWO_YEARS_AGO = 'one_two_years_ago',
  MORE_THAN_THREE_YEARS_AGO = 'more_than_three_years_ago',
  NEVER = 'never',
}

export enum TargetBodyType {
  REGULAR = 'regular',
  FIT = 'fit',
  FLAT_STOMACH = 'flat_stomach',
  ATHLETIC = 'athletic',
  CURVY = 'curvy',
  CUT = 'cut',
  SHREDDED = 'shredded',
  BULK = 'bulk',
  EXTRA_BULK = 'extra_bulk',
  MUSCLE_MAN = 'muscle_man',
}

export enum HealthGoals {
  LOSE_WEIGHT = 'lose_weight',
  INCREASE_LIFE_EXPECTANCY = 'increase_life_expectancy',
  BOOST_TRAIN_POWER = 'boost_train_power',
  IMPROVE_BLOOD_PRESSURE = 'improve_blood_pressure',
  REDUCE_CHOLESTEROL_LEVEL = 'reduce_cholesterol_level',
  SLEEP_BETTER = 'sleep_better',
  IMPROVE_BONE_HEALTH = 'improve_bone_health',
  REDUCE_RISKS_CANCER = 'reduce_risks_cancer',
}

export enum CurrentBodyType {
  REGULAR = 'regular',
  FLABBY = 'flabby',
  EXTRA = 'extra',
  OVERWEIGHT = 'overweight',
  OBESE = 'obese',
}

export enum Focus {
  SHAPE = 'shape',
  STRESS_REDUCTION = 'stress_reduction',
  ENERGY = 'energy',
  PHYSIQUE = 'physique',
  HEART_HEALTH = 'heart_health',
  SLEEP = 'sleep',
  RELAXATION = 'relaxation',
  RELATIONSHIP_WITH_FOOD = 'relationship_with_food',
  UNDERSTAND_BODY = 'understand_body',
}

export enum TargetZones {
  ARMS = 'arms',
  BACK = 'back',
  LOWER_BACK = 'lower_back',
  BELLY = 'belly',
  BUTTOCKS = 'buttocks',
  FACE = 'face',
  KNEES = 'knees',
  LEGS = 'legs',
  NECK = 'neck',
  PECS = 'pecs',
  WHOLE = 'whole',
}

export enum FitnessLevel {
  BEGINNER = 'beginner',
  INTERMEDIATE = 'intermediate',
  ADVANCED = 'advanced',
}

export enum LifeBusyness {
  WORK = 'work',
  FAMILY_KIDS = 'family_kids',
  OUTDOOR_ACTIVITIES = 'outdoor_activities',
  SOCIAL_WORK = 'social_work',
  OTHER = 'other',
}

export enum WeightGainReasons {
  COVID_19_PANDEMIC = 'covid_19_pandemic',
  MARRIAGE_OR_RELATIONSHIP = 'marriage_or_relationship',
  MENOPAUSE = 'menopause',
  BUSIER_WORK_AND_FAMILY_LIFE = 'busier_work_and_family_life',
  STRESS_OR_MENTAL_HEALTH = 'stress_or_mental_health',
  OTHER = 'other',
  NONE = 'none',
}

export enum HealthIssues {
  HIGH_BLOOD_PRESSURE = 'high_blood_pressure',
  DIABETES = 'diabetes',
  HIGH_CHOLESTEROL = 'high_cholesterol',
  DEPRESSION = 'depression',
  OTHER = 'other',
  NONE = 'none',
}

export enum TriedDiets {
  VEGETERIAN = 'vegeterian_diet',
  VEGAN = 'vegan_diet',
  KETO = 'keto_diet',
  MEDI = 'mediterranean_diet',
  NONE = 'none',
}

export enum FoodCravings {
  SWEETS = 'sweets',
  SALTY = 'salty',
  ALCOHOLIC_SWEETENED_BEVERAGES = 'alcohol',
  ANY_FOOD = 'any_food',
  NEVER = 'never',
  OTHER = 'other',
}

export enum HungryTimeOfDay {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
  NIGHT = 'night',
}

export enum StressEating {
  OVEREAT_UNHEALTHY = 'overeat',
  EAT_AS_USUAL = 'eat_as_usual',
  HARD_TO_EAT_WHEN_STRESSED = 'not_eat',
  OTHER = 'other',
}

export enum PreferredMeal {
  HOME_COOKED = 'home_cooked',
  FAST_FOOD_TAKEOUT = 'fast_food',
  RESTAURANT_DINING = 'restaurant_dining',
  SNACKS_THROUGHOUT_THE_DAY = 'snacks',
}

export enum EatingHabits {
  MOSTLY_BALANCED_AND_HEALTHY = 'balanced_and_healthy',
  SOMETIMES_HEALTHY_SOMETIMES_INDULGENT = 'healthy_and_indulgent',
  MOSTLY_INDULGENT_AND_UNHEALTHY = 'indulgent_and_unhealthy',
}

export enum YesNoMaybe {
  YES = 'yes',
  NO = 'no',
  MAYBE = 'maybe',
}

export enum YesNo {
  YES = 'yes',
  NO = 'no',
}

export enum YesNoRarelySometimes {
  YES = 'yes_frequently',
  SOMETIMES = 'sometimes',
  RARELY = 'rarely',
  NO = 'no_never',
}

export enum BehaviorTrigger {
  FOOD_AROUND_ME = 'food_around_me',
  BOREDOM = 'boredom',
  EMOTIONAL_STATE = 'emotional_state',
  OTHER_PEOPLE_EATING = 'other_people_eating',
  HUNGER = 'hunger',
  SOMETHING_ELSE = 'something_else',
}

export enum YesNoSometimes {
  YES = 'yes',
  NO = 'no',
  SOMETIMES = 'sometimes',
}

export enum WeightLossPace {
  ASAP = 'asap',
  SLOW = 'slow',
  IN_BETWEEN = 'in_between',
}

export enum WeightLossFocus {
  HABITS = 'healthy_habits',
  NUTRITION = 'nutrition',
  ACTIVITY = 'activity',
  OTHER = 'other',
}

export enum DebqFrequencyOptions {
  NEVER = '1',
  SELDOM = '2',
  SOMETIMES = '3',
  OFTEN = '4',
  VERY_OFTEN = '5',
}

export enum Occasion {
  VACATION = 'vacation',
  WEDDING = 'wedding',
  SPORTING_EVENT = 'sporting_event',
  BEACH_TRIP = 'beach_trip',
  REUNION = 'reunion',
  FAMILY_OCCASION = 'family_occasion',
  OTHER = 'other',
  NO = 'no',
}

export enum WeightLossMotivations {
  FEEL_BETTER = 'feel_better',
  INCREASE_ENERGY = 'increase_energy',
  ENJOY_THINGS = 'enjoy_things',
  SET_EXAMPLE = 'set_example',
  BETTER_LIFE_QUALITY = 'better_life_quality',
  OTHER = 'other',
}

export enum HealthGoalReason {
  WEIGHT_LOSS = 'loose_weight',
  HEALTH = 'be_healthier',
  RELATIONSHIPS_WITH_FOOD = 'relationships_with_food',
  ENERGY_INCREASE = 'energy_increase',
  HEALTHY_HABITS = 'healthy_habits',
}
